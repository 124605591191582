import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ImvUiModal } from '../../ui-components/public-api';
import { Subscription } from 'rxjs';
import { ActionDialogInterface } from '../action-dialog.Interface';
import { DialogsService } from '../dialogs.service';
import { DIALOG_ERROR_CODES } from './dialog.error-codes';
import { Trace } from '../../models/shared.model';

@Component({
	selector: 'imv-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit, OnDestroy {
	@Input() i18n: any;
	@ViewChild('modal') modal!: ImvUiModal;
	@Output() doAction: EventEmitter<ActionDialogInterface> = new EventEmitter();

	title!: string;
	description!: string;
	action!: string;
	isMobile!: boolean;
	error: any;
	is18nLoaded!: boolean;
	handleEventSubscription!: Subscription;
	private readonly GLOBAL_ERRORS: string[] = ['UNHANDLED_ERROR'];
	CUSTOM_ERRORS: string[] = [
		...DIALOG_ERROR_CODES,
		'ERROR_DUPLA_INCORRECTA',
		'ERROR_DUPLICIDAD',
		'ERROR_CADUCADO_INEXISTENTE',
		'EMPTY_RESULT',
		'TECH_007',
		'ERROR_VALIDACION_PRESENTAR',
		'INVALID_TOKEN_004',
		'INVALID_TOKEN_005',
		'INVALID_TOKEN_002',
		'INVALID_TOKEN',
		'ERROR_CSV_NO_EXISTE',
		'ERROR_SERVICIO_EXTERNO',
		'ERROR_TOKEN_EXTERNO',
		'ERROR_GENERICO_EXTERNO',
		'ERROR_GUARDAR_EXPEDIENTE',
		'ERROR_EMAIL_INEXISTENTE',
		'ERROR_ENVIO_CORREO',
		'ERROR_EMAILAUTH',
		'VERSION_FORMULARIO_INVALIDA',
		'INSUFFICIENT_ARGUMENTS',
		'BUSI_011',
		'BUSI_012',
		'BUSI_043',
		'ERROR_REGISTRO',
		'BUSI_EXPEDIENTE_ALTA',
		// custom errors
		'ERROR_NUMERO_REGISTRO',
		'ERROR_CONEXION_APPIAN_VALIDACION',
		'INSUFFICIENT_ARGUMENTS',
		'ERROR_VALIDACION_FORMULARIO',
		...this.GLOBAL_ERRORS,
		'DIALOG_ALERT_EMPTY_DOCUMENTS',
	];
	traces: Trace[] = [];

	constructor(
		public dialogsService: DialogsService,
		public detectChanges: ChangeDetectorRef,
	) {
		this.checkIsMobile();
	 }

	ngOnDestroy(): void {
		this.handleEventSubscription?.unsubscribe();
	}

	ngOnInit(): void {
		this.loadSubscriptions();
		this.detectChanges.detectChanges();
	}

	loadSubscriptions = () => {
		this.handleEventSubscription = this.dialogsService.handleError.subscribe({
			next: (errorStr: string | null) => {
				if (errorStr) {
					const error = JSON.parse(errorStr);
					if (this.CUSTOM_ERRORS.includes(error.errorCode)) {
						this.error = error;
						this.is18nLoaded = true;
						this.open();
					}
				}
			},
		});
	};

	openDialogEmitter = (errorCode: any) => this.dialogEmitter('OPEN', errorCode);
	closeDialogEmitter = (errorCode: any) => this.dialogEmitter('CLOSE', errorCode);
	dialogEmitter(action: string, errorCode: any) {
		const error: ActionDialogInterface = { action, errorCode };
		this.dialogsService.action.next(error);
		this.doAction.emit(error);
	}
	setLabels = () => {
		const errorCode = this.error?.errorCode;
		if (errorCode && this.i18n[errorCode]) {
			const { title, description, action } = this.i18n[errorCode];
			this.title = title;
			this.description = description;
			this.action = action;
		} else {
			this.title = 'Ha ocurrido un error';
			this.description = this.error.message;
			this.action = 'Entendido';
		}
		this.traces = this.error.traces;
	};
	open = () => {
		this.setLabels();
		this.openDialogEmitter(this.error.errorCode);
		this.modal.open();
	};
	close = () => {
		this.closeDialogEmitter(this.error.errorCode);
		this.modal.close();
	};
	@HostListener('window:resize', ['$event'])
	onResize = (event: any) => (this.isMobile = event.target.innerWidth <= 670);

	/**
	 * Returns true if the width of the window is less or equal than 670px
	 *
	 * @returns
	 */
	checkIsMobile(): boolean {
		if (window.innerWidth <= 670) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
		return this.isMobile;
	}
	
}
