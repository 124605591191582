import { createAction, props } from '@ngrx/store';
import { BaremosDto, RefusalDto } from '@shared/dtos';
import { FormField, QuestionResponse } from '@shared/models';
import { EQuestionnaireActions } from './questionnaire.state';

/**
 * Public. Loads the questions
 */
export const LOAD_QUESTIONS = createAction(EQuestionnaireActions.LOAD_QUESTIONS);

/**
 * Public. Initializes the questionnaire
 */
export const INIT_QUESTIONNAIRE = createAction(EQuestionnaireActions.INIT_QUESTIONNAIRE);

/**
 * Public. Gets next question
 */
export const NEX_QUESTION = createAction(EQuestionnaireActions.NEXT_QUESTION, props<{ payload: QuestionResponse }>());

/**
 * Public. Gets previous question
 */
export const PREVIOUS_QUESTION = createAction(EQuestionnaireActions.PREVIOUS_QUESTION);

/**
 * Public. Cancels the questionnaire
 */
export const CANCEL_CUESTIONNAIRE = createAction(EQuestionnaireActions.CANCEL_QUESTIONNAIRE);

/**
 * Public. Generate the questions
 */
export const GENERATE_QUESTIONS = createAction(EQuestionnaireActions.GENERATE_QUESTIONS);

/**
 * Private. Loads success questions
 */
export const LOAD_QUESTIONS_SUCCESS = createAction(EQuestionnaireActions.LOAD_QUESTIONS_SUCCESS, props<{ questions: FormField[]; baremos: BaremosDto; refusals: RefusalDto[] }>());

/**
 * Private. Loads fail questions
 */
export const LOAD_QUESTIONS_FAIL = createAction(EQuestionnaireActions.LOAD_QUESTIONS_FAIL);

/**
 * Private. Sets the question
 */
export const SET_QUESTION = createAction(EQuestionnaireActions.SET_QUESTION, props<{ payload: number }>());

/**
 * Private. Adds a response
 */
export const AD_RESPONSE = createAction(EQuestionnaireActions.ADD_RESPONSE, props<{ key: string; value: string; parent: string }>());

/**
 * Private. Removes last response
 */
export const REMOVE_LAST_RESPONSE = createAction(EQuestionnaireActions.REMOVE_LAST_RESPONSE);

/**
 * Private. Save the questions
 */
export const SAVE_QUESTIONS = createAction(EQuestionnaireActions.SAVE_QUESTIONS, props<{ payload: FormField[] }>());
