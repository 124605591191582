import { RefusalDto } from '@shared/dtos';
import { FormField, QuestionResponse } from '@shared/models';

/**
 * Feature store name
 */
export const FEATURE_STORE_NAME = 'Question';

/**
 * Questionnaire actions enum. Contains load questions, load questions success, load questions fail, cancel questionnaire, init questionnaire,
 * set question, generate questions, save questions, add response, remove last response, next question and previous question.
 */
export enum EQuestionnaireActions {
	LOAD_QUESTIONS = '[Questionnaire] Load questions',
	LOAD_QUESTIONS_SUCCESS = '[Questionnaire] questions successfully loaded',
	LOAD_QUESTIONS_FAIL = '[Questionnaire] questions loaded failed',
	CANCEL_QUESTIONNAIRE = '[Questionnaire] Cancel questionaire',
	INIT_QUESTIONNAIRE = '[Questionnaire] Init questionaire',
	SET_QUESTION = '[Questionnaire] Set question',
	GENERATE_QUESTIONS = '[Questionnaire] Generate multiple questions',
	SAVE_QUESTIONS = '[Questionnaire] Save multiple questions',
	ADD_RESPONSE = '[Questionnaire] Add response',
	REMOVE_LAST_RESPONSE = '[Questionnaire] Remove last response',
	NEXT_QUESTION = '[Questionnaire] Next question',
	PREVIOUS_QUESTION = '[Questionnaire] Previous question',
}

/**
 * Questionnaire Store
 */
export interface IQuestionnaireStore {
	/**
	 * Current number
	 */
	current: number;

	/**
	 * Form field questions
	 */
	questions: FormField[];

	/**
	 * Baremos
	 */
	baremos: any;

	/**
	 * Refusals
	 */
	refusals: RefusalDto[];

	/**
	 * Monoparentalidad
	 */
	monoparentalidad: string;

	/**
	 * Discapacidad
	 */
	discapacidad: string;

	/**
	 * Question responses history
	 */
	history: QuestionResponse[];

	/**
	 * Responses
	 */
	responses: any;

	/**
	 * Variables
	 */
	variables: any;
}
