
// core-expressions.ts
export const REGULAR_EXPRESSIONS = {
    // Identificadores
    dniPattern: /^\d{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i,
    dniSupportPattern: /^[a-zA-Z]{3}\d{6}$/,
    niePattern: /^[XYZ]\d{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i,
    nieSupportPattern: /^[EC]\d{8}$/,
    csv: /[a-z0-9]{8}/gi,
    iban: /ES\d{22}$/,
    
    // Texto
    alphabeticalPattern: /^[a-zA-ZÀ-ÿÑñ\-]+( [a-zA-ZÀ-ÿÑñ\-]+)*$/,
    alphanumericPattern: /^[a-zñ0-9]+( [a-zñ0-9]+)*$/,
    
    // Números y dinero
    moneyPattern: /^\d*(?:\,\d{1,2})?$/,
    phonePattern: /^(\d[ -]*){9}$/,
    numberPattern: /^[0-9]*$/,

    // Utilidades
    notAllowingSpaces: /^\S*$/,
    maxLength17: /^.{1,17}$/,
    
    // Email
    emailPattern: /^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
  };