/**
 * Feature store name reference
 */
export const FEATURE_STORE_NAME = 'Core';

/**
 * Ecore actions enum reference
 */
export enum ECoreActions {
	/**
	 * Settings language const
	 */
	SET_LANG = '[Core] Setting language',
	/**
	 * Settings theme const
	 */
	SET_THEME = '[Core] Setting theme',
	/**
	 * Initial login CAISS const
	 */
	INIT_LOGIN_CAISS = '[Core] Init Login Caiss',
}

/**
 * Core store interface
 */
export interface ICoreStore {
	/**
	 * Current language reference
	 */
	i18n?: {
		currentLang: string;
	};
	/**
	 * Theme reference
	 */
	theme?: ICoreTheme;
}

/**
 * Theme interface
 */
export interface ICoreTheme {
	/**
	 * Theme name reference
	 */
	name?: string;
	/**
	 * Variant theme reference
	 */
	variant?: string;
}
