import { IIdentification } from "../interfaces/identification.interface";


/**
 * Feature store name
 */
export const FEATURE_STORE_NAME = 'Identification';

/**
 * Identification action enum. Contains save
 */
export enum EIdentificationActions {
	SAVE = '[Identification] Save user',
}

/**
 * Identification store
 */
export interface IIdentificationStore {
	/**
	 * User
	 */
	user: IIdentification;
}
