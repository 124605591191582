import { APP_CONFIG, AuthService, ConfigManager, GTMService, INIT_LOGIN_CAISS, SET_LANG, SET_TEHEME } from '@app/core';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import {  CANCEL_CUESTIONNAIRE } from '@app/questionnaire';
const ENTER = 'Enter';

import { ENVIRONMENT } from '../environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ImvUiCookiesBanner, ImvUiModal } from '@shared/ui-components';
import { NavigationComponent } from './shared/components/navigation/navigation.component';
import { resetForm } from '@shared/store';
import { CommonModalsService } from '@shared/services';
import { ValidationError } from '@shared/models';
import { languages } from './languages';
import { UpdateService } from './core/services/update/update.service';
import { Subscription } from 'rxjs';
import { FeatureToggleService } from './core/services/feature-toggle/feature-toggle.service';
import { PageComponent } from './shared/components/page/page.component';
import { Analytical } from './core/modules/analytical/Analytical';
import { PartialUrl } from './core/enums/partial-url.enum';

const requestIMVQuestionnaire = { name: 'Solicitud del IMV', href: PartialUrl.QUESTIONNAIRE };
/**
 * App component
 */
@Component({
	selector: 'imv-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent extends PageComponent implements OnInit, AfterViewInit, OnDestroy {
	/**
	 * CAISS reference
	 */
	/**
	 * Enabled login Caiss reference
	 */
	readonly enabledLoginCaiss: boolean;
	/**
	 * Current language reference
	 */
	_currentLanguage!: string;
	/**
	 * Home reference
	 */
	isHome!: boolean;
	/**
	 * Consult reference
	 */
	isConsultComponent = false;
	/**
	 * Breadcrumb reference
	 */
	isBreadcrumb = false;
	/**
	 * Navigation reference
	 */
	isNavigation = false;
	/**
	 * Stepper reference
	 */
	isStepper = false;
	/**
	 * Error 404 reference
	 */
	isError404 = false;
	/**
	 * Error 500 reference
	 */
	isError500 = false;
	/**
	 * Maintenance reference
	 */
	isMaintenance = false;
	/**
	 * Proceedings reference
	 */
	isProceedings = false;
	/**
	 * Form step reference, to show different cancel message
	 */
	isFormStep = false;
	/**
	 * Array with readcrumbs items
	 */
	breadcrumbsItems: Array<any> = [{ name: 'Inicio', href: PartialUrl.HOME }, requestIMVQuestionnaire];
	/**
	 * Valitation error description
	 */
	validationErrorDescription = 'Descripción del error de validaciones';
	/**
	 * Validation error service down reference
	 */
	validationErrorServiceDown = false;
	/**
	 * General error message
	 */
	generalErrorMessage = 'Mensaje de error';
	/**
	 * Current url main
	 */
	currentUrlMain!: string;
	/**
	 * Current url frequent question
	 */
	currentUrlfrequentQuestion!: string;
	/**
	 *  Value reference
	 */
	value: any;
	/**
	 * Exported array from languages.ts
	 */
	languages = languages;
	/**
	 * Image info reference
	 */
	imageLink!: any;

	i18nDialogs: any = {};

	/**
	 * Modal cancel reference
	 */
	@ViewChild('modalCancel') modalCancel!: ImvUiModal;

	@ViewChild('cookiesBanner') cookiesBanner!: ImvUiCookiesBanner;
	/**
	 * Modal validation error reference
	 */
	@ViewChild('modalValidationError') modalValidationError!: ImvUiModal;
	/**
	 * Modal general error reference
	 */
	@ViewChild('modalGeneralError') modalGeneralError!: ImvUiModal;
	/**
	 * Modal loading reference
	 */
	@ViewChild('modalLoading') modalLoading!: ImvUiModal;
	/**
	 * Modal draft version reference
	 */
	@ViewChild('modalDraftVersion') modalDraftVersion!: ImvUiModal;
	/**
	 * Modal new version reference
	 */
	@ViewChild('modalNewVersion') modalNewVersion!: ImvUiModal;
	/**
	 * Imv navigation reference
	 */
	@ViewChild('imvNavigation')
	imvNavigation!: NavigationComponent;
	/**
	 * Modal csv still valid
	 */
	@ViewChild('modalcsvVigente') modalcsvVigente!: ImvUiModal;
	private handleDialogErrorsSubscription!: Subscription;
	private routerEventsSubscription!: Subscription;
	private translateServiceSubscription!: Subscription;
	private checkUpdateObsoleteSubscription!: Subscription;
	private validationErrorModalObservableSubscription!: Subscription;
	private generalErrorModalObservableSubscription!: Subscription;
	private goHomeModalObservableSubscription!: Subscription;
	private documentLoadingModalObservableSubscription!: Subscription;
	private draftVersionModalObservableSubscription!: Subscription;
	analytical: any;
	/**
	 * Set languague passed by param
	 *
	 * @param event
	 */
	changeLangs(event: any) {
		const value = event.target.dataset.value;
		if (this.languages.map(lang => lang.value).includes(value)) {
			this.changeLanguage(value);
		}
	}

	/**
	 * Contains all data, check parameter url, set application configuration and set CAISS = 1
	 *
	 * @param _configManager
	 * @param _translateService
	 * @param _store
	 * @param activatedRoute
	 * @param router
	 * @param _modalsService
	 * @param _elementRef
	 * @param _authCaissService
	 * @param _updateService
	 */
	constructor(
		@Inject(APP_CONFIG) private _configManager: ConfigManager,
		private _translateService: TranslateService,
		private _store: Store,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private _modalsService: CommonModalsService,
		private _elementRef: ElementRef,
		private _authService: AuthService,
		private _updateService: UpdateService,
		injector: Injector,
		private featureToggleService: FeatureToggleService,
	) {
		super(injector);
		console.log(ENVIRONMENT.envVersion);
		this.checkParameterUrl();
		this.enabledLoginCaiss = this.appConfig.loginCaiss === 1;
	}

	ngOnDestroy(): void {
		this.handleDialogErrorsSubscription?.unsubscribe();
		this.routerEventsSubscription?.unsubscribe();
		this.translateServiceSubscription?.unsubscribe();
		this.checkUpdateObsoleteSubscription?.unsubscribe();
		this.validationErrorModalObservableSubscription?.unsubscribe();
		this.generalErrorModalObservableSubscription?.unsubscribe();
		this.goHomeModalObservableSubscription?.unsubscribe();
		this.documentLoadingModalObservableSubscription?.unsubscribe();
		this.draftVersionModalObservableSubscription?.unsubscribe();
	}

	/**
	 * On click image calls handleHommeNavigationRequest()
	 *
	 * @param event
	 */
	@HostListener('clickImage', ['$event'])
	onClickImage() {
		if (!this.isHome && !this.isMaintenance) {
			this.handleHomeNavigationRequest();
		}
	}

	/**
	 *  Called to initialize a component and views, adds languages, and sets image href and name
	 */
	ngOnInit(): void {
		this._translateService.addLangs(this._configManager.config?.i18n?.langs || [ENVIRONMENT.defaultLang]);
		this.languageTranslateService();
		this.imageLink = {
			href: 'https://www.seg-social.es/wps/portal/wss/internet/Inicio',
			name: 'Inicio',
			role: 'link'
			
		};
		this.translateServiceSubscription = this._translateService.get('shared.dialogs').subscribe({ next: i18nDialogs => (this.i18nDialogs = i18nDialogs) });
		if (this.isCaiss) {
			this.translateServiceSubscription = this._translateService
				.get('shared.dialogsCAIS')
				.subscribe({ next: dialogsCAIS => (this.i18nDialogs = { ...this.i18nDialogs, ...dialogsCAIS }) });
		}

		this.initializeAnalytics();
	}

	private initializeAnalytics() {
		if (this.isCitizen && this.hasConsentCookie() && !this.analytical) {
			this.analytical = new Analytical(this.gtmService, this.router, this.featureToggleService, this.activatedRoute);
			this.analytical.init(this.appConfig);
			this.pushTagAcceptCookies();
		}
	}

	private pushTagAcceptCookies() {
		this.gtmService.pushTag({ event: 'acceptCookies' }).then(res => res, err => err);
	}

	/**
	 * Called after initialize component and views, subscribes to open new version modal
	 */
	ngAfterViewInit() {
		this.checkForUpdate();
	}

	private checkForUpdate() {
		this.checkUpdateObsoleteSubscription = this._updateService.checkUpdate()
			.subscribe({
				next: (isNewVersionDetected: boolean) => isNewVersionDetected,
				error: err => console.log(err)
			});
	}

	private hasConsentCookie(): boolean {
		const cookie = this.getCookie('hasConsent');
		return cookie === 'true';
	}

	private getCookie(name: string): string | null {
		const nameLenPlus = (name.length + 1);
		return document.cookie
			.split(';')
			.map(c => c.trim())
			.filter(cookie => {
				return cookie.substring(0, nameLenPlus) === `${name}=`;
			})
			.map(cookie => {
				return decodeURIComponent(cookie.substring(nameLenPlus));
			})[0] || null;
	}

	/**
	 * Translates languague service to language passed by param
	 *
	 * @param language
	 */
	languageTranslateService(language?: string) {
		const availableLanguages = this._translateService.getLangs();
		const defaultLang = this._configManager.config?.i18n?.default || ENVIRONMENT.defaultLang;
		const browserLang = this._translateService.getBrowserLang() || 'es';
		const defLan = availableLanguages.indexOf(browserLang) > -1 ? browserLang : defaultLang;
		this._currentLanguage = language ? language : defLan;
		this._translateService.setDefaultLang(this._currentLanguage);
		this.changeLang(this._currentLanguage);
		this.initModalsService();
	}

	/**
	 * Call it or active when the dropdown is created
	 *
	 * @param language
	 */
	changeLanguage(language: string) {
		this._translateService.addLangs(this.languages.map(lang => lang.value));
		this.languageTranslateService(language);
	}

	/**
	 * If enter is pressed set focus on content main
	 *
	 * @param event
	 */
	skipToContent(event: KeyboardEvent) {
		if (event.key === ENTER) {
			const elementR = this._elementRef.nativeElement.querySelector('#content-main');
			if (elementR) {
				elementR.focus();
			}
		}
	}

	/**
	 * If enter is pressed set focus on frequent question
	 *
	 * @param event
	 */
	frequentQuestion(event: KeyboardEvent) {
		if (event.key === ENTER) {
			const elementQ = this._elementRef.nativeElement.querySelector('#frequentQuestion');
			if (elementQ) {
				elementQ.focus();
			}
		}
	}

	/**
	 * Initialize modal services
	 */
	initModalsService() {
		this.validationErrorModalObservableSubscription = this._modalsService.validationErrorModalObservable().subscribe({
			next: (data: ValidationError) => {
				this.validationErrorServiceDown = data.serviceDown;
				this.validationErrorDescription = data.errorMessage;
				this.modalValidationError.open();
			},
		});

		this.generalErrorModalObservableSubscription = this._modalsService.generalErrorModalObservable().subscribe({
			next: errorMessage => {
				this.generalErrorMessage = errorMessage;
				this.modalGeneralError.open();
			},
		});

		this.goHomeModalObservableSubscription = this._modalsService.goHomeModalObservable().subscribe({ next: () => this.handleHomeNavigationRequest() });

		this.documentLoadingModalObservableSubscription = this._modalsService.documentLoadingModalObservable().subscribe({
			next: message => {
				if (message === 'open') {
					this.modalLoading.open();
				} else if (message === 'close') {
					this.modalLoading.close();
				}
			},
		});

		this.draftVersionModalObservableSubscription = this._modalsService.draftVersionModalObservable().subscribe({
			next: () => {
				this.modalDraftVersion.open();
			},
		});
	}

	/**
	 * Subscribes to url changes and controls Angular routing dependings on url changes
	 */
	checkParameterUrl() {
		this.routerEventsSubscription = this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				map(() => this.activatedRoute),
				map((route: any) => {
					while (route.firstChild) {
						route = route.firstChild;
					}
					return route;
				}),
				filter((route: any) => route.outlet === 'primary'),
				mergeMap((route: any) => route.data),
			)
			.subscribe({
				next: (data: any) => {
					this.setLayout(data);
					this.checkCaissLogin();
					this.checkLanguageIsInQueryParams(this.activatedRoute);
				},
			});
	}

	private checkLanguageIsInQueryParams(activatedRoute: ActivatedRoute) {
		const lang = activatedRoute.snapshot.queryParams['lang'];
		if (lang && this.languages.map(lang => lang.value).includes(lang)) {
			this._currentLanguage = lang;
			this.changeLanguage(lang);
		}
	}

	/**
	 * Change language to lang param
	 *
	 * @param lang
	 */
	changeLang(lang: string): void {
		this._store.dispatch(SET_LANG(lang));
	}

	/**
	 * Change theme with name and variant param
	 *
	 * @param name
	 * @param variant
	 */
	changeTheme(name: string, variant: string): void {
		this._store.dispatch(SET_TEHEME({ name, variant }));
	}

	/**
	 * Navigate url to param passed by event
	 *
	 * @param event
	 */
	eventNavigation(event: any) {
		const url = event.detail.href;
		this.router.navigateByUrl(url);
	}

	/**
	 * Navigates to home depends on  itemBreadCrumb, using breadcrumbs or using cancel button
	 *
	 * @param itemBreadCrumb
	 */
	handleHomeNavigationRequest(itemBreadCrumb?: any) {
		// Going home using breadcrumbs
		if (itemBreadCrumb) {
			if (itemBreadCrumb.detail.href === PartialUrl.HOME && this.isConsultComponent) {
				this.goHome(); // Go home without asking anything else
				return;
			} else if (itemBreadCrumb.detail.href === PartialUrl.HOME) {
				this.modalCancel.open();
			}
		}
		if (!itemBreadCrumb && this.isConsultComponent) {
			this.goHome();
		} else {
			this.modalCancel.open();
		}
	}

	/**
	 * Set province true, cancel questionnaire, reset form and clean timer if imvNavigation is true
	 *
	 * @param openProvinceModal
	 */
	goHome(openProvinceModal = false) {
		this.router.navigateByUrl(openProvinceModal ? PartialUrl.PROVINCE_TRUE : PartialUrl.BASE).then(() => {
			this._store.dispatch( CANCEL_CUESTIONNAIRE());
			this._store.dispatch(resetForm());
		});
	}

	/**
	 * Depends on open param closes cancel modal and go home or just close cancel modal
	 *
	 * @param open
	 */
	onConfirmCancel(open = false) {
		this.goHome(open);
		this.closeCancelModal();
		if (this.modalDraftVersion.isOpen) {
			this.modalDraftVersion.close();
		}
	}

	/**
	 * Closes cancel modal
	 */
	closeCancelModal() {
		this.modalCancel.close();
	}

	/**
	 * Closes validation error modal
	 */
	closeErrorModal() {
		this._modalsService.generalErrorCloseModalEvent();
		this.modalGeneralError.close();
	}

	/**
	 * Closes the validation error modal
	 */
	closeValidationErrorModal() {
		this.modalValidationError.close();
	}

	/**
	 * Reloads page
	 */
	reloadPage() {
		location.reload();
	}

	/**
	 * Check if is CAISS login
	 */
	private checkCaissLogin() {
		if (this.isCaiss && this.enabledLoginCaiss && !this._authService.hasToken() && !this.isError500 && !this.isMaintenance) {
			this._store.dispatch(INIT_LOGIN_CAISS());
		}
	}

	acceptCookiesBanner = async () => {
		await this.cookiesBanner.acceptCookies();
		if (this.isCitizen) {
			this.initializeAnalytics();
			this.gtmService.pageNameSubject.next([GTMService.TENANT, document.title].join('|'));
		}
	}

	rejectCookiesBanner = async () => {
		await this.cookiesBanner.rejectCookies();
		console.log(window.dataLayer);
		// envío de evento rejectCookies a datalayer
		// if (this.isCitizen) {
		// 	await this.gtmService.pushTag({ event: 'rejectCookies' });
		// }
	}

	/**
	 * Set all data to false and change breadcrumbs depends on data.home, data.module
	 *
	 * @param data
	 */
	private setLayout(data: DataLayoutBreadcrumb) {
		this.isHome = false;
		this.isStepper = false;
		this.isNavigation = false;
		this.isBreadcrumb = false;
		this.isError404 = false;
		this.isError500 = false;
		this.isMaintenance = false;
		this.isProceedings = false;
		this.isConsultComponent = false;
		this.isFormStep = false;
		if (data.home === true) {
			this.isHome = true;
			this.isConsultComponent = false;
			this.breadcrumbsItems = [{ name: 'Inicio', href: PartialUrl.HOME }, requestIMVQuestionnaire];
		} else if (data.module === 'request') {
			this.isHome = false;
			this.isConsultComponent = true;
			this.breadcrumbsItems = [
				{ name: 'Inicio', href: PartialUrl.HOME },
				{ name: 'Accede al borrador de la solicitud', href: PartialUrl.REQUEST },
			];
		} else if (data.module === 'consult-request') {
			this.isHome = false;
			this.isConsultComponent = true;
			this.breadcrumbsItems = [
				{ name: 'Inicio', href: PartialUrl.HOME },
				{ name: 'Gestiona la solicitud presentada', href: PartialUrl.CONSULT },
			];
		} else if (data.module === 'questionnaire-home') {
			this.isHome = false;
			this.isConsultComponent = false;
			this.isBreadcrumb = true;
			this.isNavigation = true;
			this.isStepper = true;
			this.breadcrumbsItems = [{ name: 'Inicio', href: PartialUrl.HOME }, requestIMVQuestionnaire];
		} else if (data.module === 'form-home') {
			this.isHome = false;
			this.isConsultComponent = false;
			this.isBreadcrumb = true;
			this.isNavigation = true;
			this.isStepper = true;
			this.breadcrumbsItems = [
				{ name: 'Inicio', href: PartialUrl.HOME },
				{ name: 'Solicitud del IMV', href: PartialUrl.FORM },
			];
		} else if (data.module === 'photo') {
			this.isHome = false;
			this.isConsultComponent = false;
			this.isBreadcrumb = false;
			this.isNavigation = false;
			this.isStepper = false;
		} else if (data.error404 === true) {
			this.isError404 = true;
			this.isHome = false;
		} else if (data.error500 === true) {
			this.isError500 = true;
			this.isHome = false;
		} else if (data.maintenance === true) {
			this.isMaintenance = true;
			this.isHome = false;
		} else if (data.isProceedings === true) {
			this.breadcrumbsItems = [
				{ name: 'Inicio', href: PartialUrl.HOME },
				{ name: 'Gestiona la solicitud presentada', href: PartialUrl.PROCEEDINGS },
			];
			this.isNavigation = false;
			this.isStepper = false;
			this.isProceedings = true;
			this.isFormStep = false;
		} else if (data.module === 'form-step') {
			this.isFormStep = true;
			this.isBreadcrumb = true;
			this.isNavigation = true;
			this.isStepper = true;
		} else {
			this.isHome = false;
			this.isBreadcrumb = true;
			this.isNavigation = true;
			this.isStepper = true;
			this.isConsultComponent = false;
			this.isError404 = false;
			this.isMaintenance = false;
			this.isProceedings = false;
			this.isFormStep = false;
		}
	}
}
export interface DataLayoutBreadcrumb {
	home: boolean;
	module: string;
	error404: boolean;
	error500: boolean;
	maintenance: boolean;
	isProceedings: boolean;
}
