import { createReducer, on, Action } from '@ngrx/store';

import { SAVE_FORM_RESPONSES, COMPLETE_STEP, INIT, NAVIGATION_CONFIG, SAVE_REQUEST_ID, SAVE_EXPEDIENT, RESET_FORM, ACTIVE_STEP, SIGNATURE_ACTION } from './shared.actions';
import { ISharedBaseStore, ISharedStore } from './shared.state';
import { Step } from '../models/shared.model';

export const CAISS_STEPS: Step[] = [

	{
		id: 1,
		text: 'Preguntas previas',
		isActive: true,
		isCompleted: false,
	},
	{
		id: 2,
		text: 'Identificación',
		isActive: false,
		isCompleted: false,
	},
	{
		id: 3,
		text: 'Formulario',
		isActive: false,
		isCompleted: false,
	},

];

export const CITIZEN_STEPS: Step[] = [
	...CAISS_STEPS,
	{
		id: 4,
		text: 'Firma',
		isActive: false,
		isCompleted: false,
	}
];


const steps: Step[] = [...CAISS_STEPS];

export const INITIAL_BASE_STATE: ISharedBaseStore = {
	step: 1,
	substep: -1,
	navigation: {
		isBegin: true,
		isNext: false,
		isDisabledNext: false,
		isLoadingNext: false,
		isDisabledBegin: false,
		isPrevious: false,
		isCancel: true,
		isSubmit: false,
	},
	savedRequestId: '',
	formResponses: {},
	expedient: {},
}

export const INITIAL_STATE: ISharedStore = {
	...INITIAL_BASE_STATE,
	steps,
};


/**
 * Creates the reducer
 */
const _sharedReducer = createReducer(
	INITIAL_STATE,

	on(NAVIGATION_CONFIG, (state, { payload }) => ({
		...state,
		navigation: {
			...state.navigation,
			...payload,
		},
	})),

	on(COMPLETE_STEP, (state, { payload }) => {
		const maxLenght = 4;
		if (payload < 1 || payload > maxLenght) {
			return state;
		}
		const steps = [...state.steps];
		steps.forEach((step, index) => steps[index] = { ...step, isActive: step.id === payload + 1, isCompleted: step.id <= payload });
		return { ...state, steps };
	}),


	on(SIGNATURE_ACTION, (state, { step }) => {
		const steps = [...state.steps, step];
		return { ...state, steps };
	}),

	on(ACTIVE_STEP, (state, { step }) => {
		const index = step - 1;
		const steps = state.steps.slice(0);
		steps[index] = { ...steps[index], isActive: true };
		return { ...state, steps };
	}),

	on(SAVE_REQUEST_ID, (state, { id }) => ({
		...state,
		savedRequestId: id,
	})),

	on(SAVE_FORM_RESPONSES, (state, { responses }) => ({
		...state,
		formResponses: { ...state.formResponses, ...responses },
	})),

	on(SAVE_EXPEDIENT, (state, { responses }) => ({
		...state,
		expedient: { ...state.expedient, ...responses },
	})),

	on(RESET_FORM, state => {
		return ({ ...state, ...INITIAL_BASE_STATE });
	}),

	on(INIT, (state, { isCaiss }) => {
		const steps = isCaiss ? CAISS_STEPS : CITIZEN_STEPS;
		return ({ ...state, steps });
	}),
);

/**
 * Shared reducer
 *
 * @param state shared store
 * @param action action
 * @returns shared reducer
 */
export function sharedReducer(state: ISharedStore | undefined, action: Action) {
	return _sharedReducer(state, action);
}
