<div role="option" [attr.aria-labelledby]="label ? 'title-' + id : undefined" *ngIf="type === 'radio'" class="imv-form-control__group-radio">
	<div class="imv-form-control__title-container">
		<h3 *ngIf="label" id="title-{{ id }}" class="imv-form-control__title-group">
			{{ label }} <span class="imv-form-control__label--optional">{{ optionalLabel }}</span>
		</h3>
		<imv-ui-tooltip [eid]="id + '-tooltip'" class="imv-form-control__title-tooltip" *ngIf="tooltip" fieldset="{{ !!tooltipMessage }}" text="{{ tooltipMessage }}"> </imv-ui-tooltip>
	</div>

	<imv-ui-selector-list #field [type]="selectorType" [options]="options" [disabled]="isDisabled" [showErrors]="showErrorSlot" [required]="required"
		><span slot="error">{{ errorMessage }}</span>
	</imv-ui-selector-list>
	<section *ngIf="showDescription">
		<ng-container *ngIf="showDescription"></ng-container>
	</section>
	<div aria-live="polite" *ngIf="description">
		<imv-ui-note class="inner-html" *ngIf="!fullDescription">
			<div slot="text" [innerHTML]="description"></div>
		</imv-ui-note>

		<imv-ui-note class="inner-html" *ngIf="fullDescription" [showMore]="true" moreLabel="Ver más" lessLabel="Ver menos">
			<div slot="text" [innerHTML]="description"></div>
			<div slot="fulltext" [innerHTML]="fullDescription"></div>
		</imv-ui-note>
	</div>
</div>
<imv-ui-autocomplete
	#field
	*ngIf="isAutoComplete"
	[eid]="id"
	[showErrors]="showErrorSlot"
	[required]="required"
	[suggestionlist]="options"
	[value]="value"
	[label]="label"
	[maxLength]="maxLength"
	[optionalLabel]="optionalLabel"
	[variant]="variant"
>
	<span slot="error">{{ errorMessage }}</span>
</imv-ui-autocomplete>
<imv-ui-input
	#field
	class="imv-form__field"
	*ngIf="type !== 'radio' && !isAutoComplete && type !== 'file' && type !== 'image' && type !== 'check' && type !== 'video-identification' && type !== 'info'"
	[eid]="id"
	[type]="type"
	[disabled]="isDisabled"
	[showErrors]="showErrorSlot"
	[options]="options"
	[label]="label"
	[optionalLabel]="optionalLabel"
	[required]="required"
	[tooltip]="tooltip"
	[variant]="variant"
	[value]="value"
	[maxLength]="maxLength"
	[prefixText]="prefixText"
	[selectedIndex]="type === 'select' ? selectedIndex : undefined"
	[upperCase]="upperCase"
>
	<span slot="error">{{ errorMessage }}</span>

	<ng-content> </ng-content>
	<div slot="tooltip">
		<imv-ui-tooltip fieldset="false" [eid]="id + '-tooltip'">
			<img slot="img" class="imv-tooltip-img-full-width" src="./assets/pwa/numero-de-soporte.png" alt="Numero de soporte" *ngIf="showTooltipImage" />
			<span slot="title" *ngIf="showTooltipImage">Donde encontrar el nº de soporte</span>
			<span slot="text" [innerHTML]="tooltipMessage"></span>
		</imv-ui-tooltip>
	</div>
</imv-ui-input>

<imv-ui-input
	*ngIf="type === 'fechacaducidad'"
	type="check"
	label="No tengo fecha de caducidad"
	(click)="checkOptionals($event)"
	[value]="controlFieldValue === '1' ? true : false"
	[disabled]="fieldControlDisabled === true"
></imv-ui-input>

<imv-ui-input
	#field
	*ngIf="type === 'check'"
	type="check"
	[eid]="id"
	[label]="label"
	[disabled]="isDisabled"
	[showErrors]="showErrorSlot"
	[required]="required"
	[tooltip]="tooltip"
	[value]="value"
>
	<span slot="error">{{ errorMessage }}</span>

	<ng-content> </ng-content>
	<div slot="tooltip">
		<imv-ui-tooltip fieldset="false" [eid]="id + '-tooltip'">
			<img slot="img" class="imv-tooltip-full-width" src="./assets/pwa/numero-de-soporte.png" alt="Numero de soporte" *ngIf="showTooltipImage" />
			<span slot="title" *ngIf="showTooltipImage">Donde encontrar el nº de soporte</span>
			<span slot="text" [innerHTML]="tooltipMessage"></span>
		</imv-ui-tooltip>
	</div>
</imv-ui-input>

<imv-ui-upload-file
	#field
	*ngIf="type === 'file'"
	[eid]="id"
	[files]="value"
	[maxFiles]="maxFiles"
	[placeholder]="placeholder"
	[showErrors]="showErrorSlot"
	[readOnly]="readonly"
	[optionalLabel]="optionalLabel"
	[disabled]="isDisabled"
	[fileError]="fileError"
	[fileUploaded]="fileUploaded"
>
	<span slot="error">{{ errorMessage }}</span>
</imv-ui-upload-file>

<imv-ui-upload-image #field [eid]="id" [value]="value" *ngIf="type === 'image'" [showErrors]="showErrorSlot" [optionalLabel]="optionalLabel" [fileUploaded]="fileUploaded">
	<span slot="error">{{ errorMessage }}</span>
</imv-ui-upload-image>

<imv-ui-videoidentification
	#field
	*ngIf="type === 'video-identification'"
	[eid]="id"
	[value]="value"
	[showErrors]="showErrorSlot"
	(noCameraClicked)="handleOpenOtherDevice()"
	(used)="handleIsUsed($event)"
>
	<span slot="error">{{ errorMessage }}</span>
</imv-ui-videoidentification>

<div *ngIf="type === 'info'" [innerHTML]="label"></div>
