import { createAction } from '@ngrx/store';

import { ECoreActions, ICoreTheme } from './core.state';

/**
 * Sets the language by creating the action with payload of type string
 */
export const SET_LANG = createAction(ECoreActions.SET_LANG, (payload: string) => ({ payload }));

/**
 * Sets the theme by creating the action with payload of type ICoreTheme
 */
export const SET_TEHEME = createAction(ECoreActions.SET_THEME, (payload: ICoreTheme) => ({ payload }));

/**
 * Initializes the CAISS login
 */
export const INIT_LOGIN_CAISS = createAction(ECoreActions.INIT_LOGIN_CAISS);
