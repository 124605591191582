export enum PartialUrl {
    BASE = '/',
    CONSULT = '/consult',
    CONSULT_RESEND_1 = '/consult?resend=1',
    FORM = '/form',
    SUMMARY = '/summary',
    HOME = '/home',
    IDENTIFICATION = '/identification',
    IDENTIFICATION_SELECT = '/identification-select',
    LOGGINGISS_ERROR_004 = '/logingiss?error=004',
    PROCEEDINGS = '/proceedings',
    PROVINCE_TRUE = '/?province=true',
    QUESTIONNAIRE = '/questionnaire',
    REQUEST = '/request',
    RESULT = '/result',
    REQUEST_RESEMD_1 = '/request?resend=1',
    FORM_STEP = '/form/step',
    FORM_STEP_1 = '/form/step/1',
    FORM_STEP_7 = '/form/step/7',
    VIDEOIDENTIFICATION = '/videoidentification',
}