import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionDialogInterface } from '../action-dialog.Interface';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
	selector: 'imv-dialog-with-control',
	templateUrl: './dialog-with-control.component.html',
	styleUrls: ['./dialog-with-control.component.scss'],
})
export class DialogWithControlComponent extends DialogComponent implements OnInit, OnDestroy {
	label!: string;
	button!: string;
	submitted = false;
	size = 8;
	frm: FormGroup = new FormGroup({
		ctrl: new FormControl(''),
	});

	override CUSTOM_ERRORS: string[] = ['ERROR_CSV_VIGENTE'];

	override setLabels = () => {
		const errorCode = this.error?.errorCode;
		if (errorCode && this.i18n[errorCode]) {
			const { title, description, action, label, button } = this.i18n[errorCode];
			this.title = title;
			this.description = description;
			this.action = action;
			this.label = label;
			this.button = button;
		} else {
			this.title = 'Ha ocurrido un error';
			this.description = this.error.message;
			this.action = 'Entendido';
			this.label = 'Error no controlado';
			this.button = 'Error no controlado';
		}
		this.traces = this.error.traces;
	};

	override open = () => {
		this.setLabels();
		this.setControls();
		this.openDialogEmitter(this.error.errorCode);
		this.modal.open();
	};

	setControls = () => {
		const controls: any = {
			ERROR_CSV_VIGENTE: new FormControl('', [Validators.required, Validators.maxLength(8)]),
		};
		const ctrl: any = controls[this.error.errorCode];
		this.frm = new FormGroup({ ctrl });
	};

	doSubmit = () => {
		if (this.frm.valid) {
			this.close();
		}
	};
	override closeDialogEmitter = (errorCode: any) => this.dialogEmitter('CLOSE', errorCode, this.frm.value);
	override dialogEmitter(action: string, errorCode: any, data?: undefined) {
		const error: ActionDialogInterface = { action, errorCode, data };
		this.dialogsService.action.next(error);
		this.doAction.emit(error);
	}
}
