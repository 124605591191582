import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';

import { SELECT_THEME } from '../../store/core.selectors';
import { APP_CONFIG } from '../../models/core.model';
import { ICoreTheme } from '../../store/core.state';
import { ConfigManager } from '../../config/config';

/**
 * Themes Service
 */
@Injectable()
export class ThemesService {
	/**
	 * Themes service constructor
	 *
	 * @param _configManager Configuration manager
	 * @param _document Document
	 * @param _logger NGX logger
	 * @param _store Store
	 */
	constructor(
		@Inject(APP_CONFIG) private _configManager: ConfigManager,
		@Inject(DOCUMENT) private _document: Document,
		private _logger: NGXLogger,
		private _store: Store,
	) {}

	/**
	 * Initializes the service and subscribes to the theme
	 */
	init(): void {
		this._store?.select(SELECT_THEME as any).subscribe({
			next: (theme: ICoreTheme | unknown) => {
				if (theme) {
					const { name, variant } = theme as ICoreTheme;
					if (name && variant) {
						this._changeTheme(name, variant);
					}
				}
			},
		});
	}

	/**
	 * Changes the theme
	 *
	 * @param name theme name
	 * @param variant theme variant
	 */
	private _changeTheme(name: string, variant: string): void {
		const availableThemes = this._configManager?.config?.themes?.available || [];
		const availableVariants = this._configManager?.config?.themes?.variants || [];
		let _name = name;
		let _variant = variant;
		if (availableThemes.indexOf(name) === -1 || availableVariants.indexOf(variant) === -1) {
			this._logger.warn(`Theme ${name} was not found, setting default theme...`);
			_name = this._configManager?.config?.themes?.default || 'default';
			_variant = this._configManager?.config?.themes?.defaultVariant || 'light';
		}
		const link = this._document.head.querySelector('#theme');
		if (link) {
			link.setAttribute('href', `assets/themes/${_name}.${_variant}.css`);
		}
	}
}
