import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { StoreModule, Store } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { InstanceManagerService } from '../services/instance-manager/instance-manager.service';
import { IAppConfig, APP_ENVIRONMENT, APP_CONFIG } from '../models/core.model';
import { ThemesService } from '../services/themes/themes.service';
import { ENVIRONMENT } from '../../../environments/environment';
import { CORE_REDUCER } from '../store/core.reducer';
import { ConfigManager } from '../config/config';
import { SET_TEHEME } from '../store/core.actions';
import { AuthInterceptor } from '../services/auth/auth.interceptor';
import { TokenService } from '../services/auth/token.service';
import { CoreLogger } from '../../core/utils/core-logger';
import { CacheInterceptor } from '../services/cache/cache.interceptor';
import { CoreEffects } from '../store/core.effects';
import { ErrorInterceptor } from '../services/auth/error.interceptor';
import { FeatureToggleService } from '../services/feature-toggle/feature-toggle.service';
import { GlobalPubSub } from '../services/feature-toggle/global-pub-sub.service';
import { AnalyticalModule } from './analytical/analytical.module';
import { CaptchaModule } from './captcha/captcha.module';
import { DateModule } from './date/date.module';
import { JwtDecodeModule } from './jwt-decode/jwt-decode.module';
import { UuidModule } from './uuid/uuid.module';
import { Observable, lastValueFrom } from 'rxjs';
import { init } from '@shared/store';
import { SanitizerInterceptor } from '../services/auth/sanitizer.interceptor';

/**
 * Initializes the application
 *
 * @param _injector Injector
 * @returns True when the initiation process finishes
 */
export const APP_INITIALIZER_FACTORY =
	(_injector: Injector): (() => Promise<boolean>) =>
		(): Promise<boolean> => {
			const configuration: Observable<IAppConfig> = _injector.get(HttpClient).get<IAppConfig>(_injector.get(APP_ENVIRONMENT).configFile);
			const promise = lastValueFrom(configuration);
			return promise
				.then((_config: IAppConfig) => {
					_injector.get(APP_CONFIG).config = _config;
					CoreLogger.level = _config.logLevel || 0;
					const name = _config?.themes?.default || 'default';
					const variant = _config?.themes?.defaultVariant || 'light';
					_injector.get(Store).dispatch(SET_TEHEME({ name, variant }));
					const isCaiss = _config.caiss === 1;
					if (isCaiss) {
						_injector.get(TokenService).enableCaiss();
					}
					_injector.get(Store).dispatch(init({ isCaiss }));
					const featureToggle$ = _injector.get(FeatureToggleService).loadFeatures(_config);

					return lastValueFrom(featureToggle$).then(() => true);
				})
				.catch((err: any) => {
					console.error(`Error al recuperar el fichero de configuración del entorno: ${_injector.get(APP_ENVIRONMENT).configFile}`);
					console.log(err);
					return true;
				});
		};

@NgModule({
	imports: [
		AnalyticalModule,
		CaptchaModule,
		DateModule,
		JwtDecodeModule,
		UuidModule,
		HttpClientModule,
		EffectsModule.forRoot([CoreEffects]),
		StoreModule.forRoot(
			{
				Core: CORE_REDUCER,
			},
			{
				runtimeChecks: {
					strictStateImmutability: true,
					strictActionImmutability: true,
					strictStateSerializability: true,
					strictActionSerializability: true,
				},
			},
		),
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			logOnly: ENVIRONMENT.production,
		}),
		LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
	],
	providers: [
		GlobalPubSub,
		{
			provide: APP_CONFIG,
			useClass: ConfigManager,
		},
		{
			provide: APP_ENVIRONMENT,
			useValue: ENVIRONMENT,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: APP_INITIALIZER_FACTORY,
			deps: [Injector],
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
			deps: [TokenService],
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: SanitizerInterceptor,
			multi: true,
			deps: [],
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CacheInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true,
			deps: [Injector],
		},
		ThemesService,
		InstanceManagerService,
		TokenService,
	],
	exports: [EffectsModule, StoreModule, StoreDevtoolsModule, HttpClientModule],
})
export class CoreModule {
	constructor(
		private _themeService: ThemesService,
		private _instanceManagerService: InstanceManagerService,
	) {
		this._themeService.init();
		this._instanceManagerService.init();
	}
}
