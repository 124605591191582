import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { GuardService } from '../services/guard/guard.service';
import { PartialUrl } from '../enums/partial-url.enum';

/**
 * Did not visit Form Guard
 */
@Injectable()
export class DidNotVisitFormGuard {
	/**
	 * Checks if the user has visited the form page
	 *
	 * @param _router Router
	 * @param _guardService Guard Service
	 */
	constructor(
		private _router: Router,
		private _guardService: GuardService,
	) {}

	/**
	 * Checks if the user can activate children
	 *
	 * @returns true if the user can active children
	 */
	canActivateChild(): boolean | UrlTree {
		return this._didNotVisitForm();
	}

	/**
	 * Checks if the user can activate an element or event
	 *
	 * @returns true if the user can active it
	 */
	canActivate(): boolean | UrlTree {
		return this._didNotVisitForm();
	}

	/**
	 * Checks if the user can load an element or event
	 *
	 * @returns true if the user can load it
	 */
	canLoad(): boolean | UrlTree {
		return this._didNotVisitForm();
	}

	/**
	 * Checks if the user has visited the form page
	 *
	 * @returns true if the user has visited the form page
	 */
	private _didNotVisitForm(): boolean | UrlTree {
		if (this._guardService.canAccessVideoidentification()) {
			return true;
		} else {
			return this._router.parseUrl(PartialUrl.HOME);
		}
	}
}
