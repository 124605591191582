import { Navigation, Step } from '../models/shared.model';

/**
 * Feature store name
 */
export const FEATURE_STORE_NAME = 'Shared';

/**
 * Navigation actions enum. Contains config
 */
export enum ENavigationActions {
	CONFIG = '[SHARED] - Configure navigation buttons',
}

/**
 * Shared actions enum. Contains init, complete step, save request id, save from responses, save expedient and reset form
 */
export enum ESharedActions {
	INIT = '[SHARED] - Init process',
	COMPLETE_STEP = '[SHARED] - Complete step',
	SAVE_REQUEST_ID = '[SHARED] - Save Request id',
	SAVE_FORM_RESPONSES = '[SHARED] - Save form responses',
	SAVE_EXPEDIENT = '[SHARED] - Save expedient',
	RESET_FORM = '[SHARED] - Reset form',
	CURRENT_STEP = '[SHARED] - Current step',
	LOAD_SIGNATURE_MODULE = '[SHARED] - Load signature module',
}


export interface ISharedBaseStore {
	step: number;
	substep: number;
	navigation: Navigation;
	savedRequestId: string;
	formResponses: { [key: string]: any };
	expedient: any;
}

export interface ISharedStore extends ISharedBaseStore {
	step: number;
	substep: number;
	navigation: Navigation;
	steps: Step[];
	savedRequestId: string;
	formResponses: { [key: string]: any };
	expedient: any;
}

