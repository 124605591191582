/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { APP_CONFIG, CacheService, ConfigManager, IAppConfig } from '@app/core';
import { Observable, map } from 'rxjs';

import { AbstractCommonListsService } from './abstract-common-lists.service';
import { ICoexistencePlanResponse } from './common-lists.model';
import { CommonListServiceMapper } from './common-list.service.mapper';

/**
 * Common lists service
 */
@Injectable()
export class CommonListsService implements AbstractCommonListsService {
	/**
	 * HTTP client
	 */
	private readonly _httpClient: HttpClient;

	/**
	 * API host
	 */
	private readonly _apiHost: string;

	/**
	 * Cache service
	 */
	private _cacheService: CacheService;

	/**
	 * Common lists service constructor
	 *
	 * @param _injector injector
	 */
	constructor(_injector: Injector) {
		const appConfig: IAppConfig = _injector.get<ConfigManager>(APP_CONFIG).config;
		this._httpClient = _injector.get(HttpClient);
		this._cacheService = _injector.get(CacheService);
		this._apiHost = appConfig.apiHost.bff + 'tablas-parametricas';
	}

	/**
	 * Gets the countries
	 *
	 * @returns countries map
	 */
	getAllCountries(): Observable<any> {
		const url = `${this._apiHost}/paises`;
		return this._cacheService.getWithCache(url).pipe(map(CommonListServiceMapper.mapParametricalCountryItemsToCountryItems));
	}

	/**
	 * Gets the provinces
	 *
	 * @returns provinces map
	 */
	getAllProvinces(): Observable<any> {
		const url = `${this._apiHost}/provincia`;
		return this._cacheService.getWithCache(url).pipe(map(CommonListServiceMapper.mapParametricalItemsToItems));
	}

	/**
	 * Gets the provinces. Full endpoint
	 *
	 * @returns provinces map
	 */
	getAllProvincesFull(): Observable<any> {
		const url = `${this._apiHost}/provincia/provinciasFull`;
		return this._cacheService.getWithCache(url).pipe(map(CommonListServiceMapper.mapParametricalItemsToItems));
	}

	/**
	 * Gets the towns by province
	 *
	 * @param province province
	 * @returns towns map
	 */
	getTowns(province: string): Observable<any> {
		const url = `${this._apiHost}/localidad/codProvincia/${province}`;
		return this._cacheService.getWithCache(url).pipe(map(CommonListServiceMapper.mapParametricalItemsToItems));
	}

	/**
	 * Gets the postal codes by province
	 *
	 * @param province province
	 * @returns postal codes map
	 */
	getPostalCodes(province: string): Observable<any> {
		const url = `${this._apiHost}/codigoPostal/codProvincia/${province}`;
		return this._cacheService.getWithCache(url).pipe(map(CommonListServiceMapper.mapParametricalElementItemsToItems));
	}

	/**
	 * Gets the address
	 *
	 * @returns adress map
	 */
	getAllAddress(): Observable<any> {
		const url = `${this._apiHost}/tipoViaPublica`;
		return this._cacheService.getWithCache(url).pipe(map(CommonListServiceMapper.mapParametricalItemsToItems));
	}

	/**
	 * Gets the marital status
	 *
	 * @returns marital status map
	 */
	getAllMaritalStatus(): Observable<any> {
		const url = `${this._apiHost}/estadoCivilIMV`;
		return this._cacheService.getWithCache(url).pipe(map(CommonListServiceMapper.mapParametricalCivilStateItemsToItems));
	}

	/**
	 * Gets the accomodation types
	 *
	 * @returns accomodation types map
	 */
	getAccommodationTypes(): Observable<any> {
		const url = `${this._apiHost}/tipoAlojamiento`;
		return this._cacheService.getWithCache(url).pipe(map(CommonListServiceMapper.mapParametricalLodgingTypeItemsToItems));
	}

	/**
	 * Gets the Entidades gestoras provinces
	 *
	 * @returns entidades gestoras provinces map
	 */
	getAllProvincesSS(): Observable<any> {
		const url = `${this._apiHost}/direccionProvincialEntidadesGestorasSS`;
		return this._cacheService.getWithCache(url).pipe(map(CommonListServiceMapper.mapParametricalItemsToItems));
	}

	/**
	 * Gets the redirect URL
	 *
	 * @param urlParam entry URL parameter
	 * @returns HTTP client
	 */
	getRedirectUrl(urlParam: string): Observable<any> {
		const url = `${this._apiHost}/redirecciones/redireccion`;
		return this._httpClient.post(url, { url: urlParam });
	}

	/**
	 * Checks if the province is pilot
	 *
	 * @param province province
	 * @returns HTTP client
	 */
	isPilotProvince(province: string): Observable<ICoexistencePlanResponse> {
		const url = `${this._apiHost}/provinciaPiloto/${province}`;
		return this._httpClient.get<ICoexistencePlanResponse>(url);
	}

	/**
	 * Access to the claim draft
	 *
	 * @param token token
	 * @returns HTTP client
	 */
	accessDraft(token: string): Observable<ICoexistencePlanResponse> {
		const url = `${this._apiHost}/accesoSolicitud/${token}`;
		return this._httpClient.get<ICoexistencePlanResponse>(url);
	}

	/**
	 * Access to the proceedings page
	 *
	 * @param token token
	 * @returns HTTP client
	 */
	accessProceedings(token: string): Observable<ICoexistencePlanResponse> {
		const url = `${this._apiHost}/accesoExpediente/${token}`;
		return this._httpClient.get<ICoexistencePlanResponse>(url);
	}
}
