import { createSelector } from '@ngrx/store';
import { AppStore } from '@shared/models';

import { IQuestionnaireStore, FEATURE_STORE_NAME } from './questionnaire.state';

/**
 * Questionnaire Questionnaire selectors
 */
export interface IFeatureAppStore extends AppStore {
	/**
	 * Feature store name
	 */
	[FEATURE_STORE_NAME]: IQuestionnaireStore;
}

/**
 * Questionnaire store selector
 *
 * @param state feature app store
 * @returns state
 */
export const SELECT_QUESTIONNAIRE_STORE = (state: IFeatureAppStore) => state[FEATURE_STORE_NAME];

/**
 * Questions selector
 */
export const SELECT_QUESTIONS = createSelector(SELECT_QUESTIONNAIRE_STORE, (state: IQuestionnaireStore) => state?.questions);

/**
 * Current selector
 */
export const SELECT_CURRENT = createSelector(SELECT_QUESTIONNAIRE_STORE, (state: IQuestionnaireStore) => state?.current);

/**
 * Questionnaire state selector
 */
export const SELECT_QUESTIONNAIRE_STATE = createSelector(SELECT_QUESTIONNAIRE_STORE, (state: IQuestionnaireStore) => state);
