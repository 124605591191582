/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
	AfterContentInit,
	Component,
	EventEmitter,
	forwardRef,
	HostListener,
	Input,
	OnInit,
	Output,
	ContentChildren,
	Injector,
	OnChanges,
	AfterViewInit,
	ViewChild,
	ViewChildren,
	QueryList,
	OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormErrorComponent } from './form-error.component';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { CommonModalsService, CommonQuestionnaireService } from '@shared/services';
import { DocumentDto } from '@shared/dtos';
import { Photo } from '@shared/models';
import { DomSanitizer } from '@angular/platform-browser';
import { ImvUiTooltip, UiUpload, FileUpload } from '../../ui-components/public-api';
import { PageComponent } from '../page/page.component';
import { Store } from '@ngrx/store';
import { SELECT_REQUEST_ID } from '../../store/shared.selectors';
const VIDEO_IDENTIFICATION = 'video-identification';
const EVENT_DETAIL = '$event.detail';
/**
 * IMV form control component
 */
@Component({
	selector: 'imv-form-control',
	templateUrl: './form-control.component.html',
	styleUrls: ['./form-control.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FormControlComponent),
			multi: true,
		},
	],
})
export class FormControlComponent extends PageComponent implements OnInit, ControlValueAccessor, AfterContentInit, OnChanges, AfterViewInit, OnDestroy {
	/**
	 * @ignore
	 * @param _
	 */
	onChange = (_: any): void => {};
	/**
	 * @ignore
	 */
	onTouched = () => {};
	/**
	 * Disabled refrence
	 */
	isDisabled = false;
	/**
	 * Upload types references
	 */
	automaticUploadType = ['file', 'image', VIDEO_IDENTIFICATION];

	/**
	 * Id input reference
	 */
	@Input() id!: string;
	/**
	 * Type input reference
	 */
	@Input() type!: string;
	/**
	 * Value input reference
	 */
	@Input() value: any = undefined;
	/**
	 * Checked input reference
	 */
	@Input() checked = false;
	/**
	 * Options input reference
	 */
	@Input() options: any[] = [];
	/**
	 * Label input reference
	 */
	@Input() label!: string;
	/**
	 * MaxLength input reference
	 */
	@Input() maxLength!: number;
	/**
	 * Prefix text input reference
	 */
	@Input() prefixText!: string | null;
	/**
	 * Optional label input reference
	 */
	@Input() optionalLabel!: string;
	/**
	 * Required input reference
	 */
	@Input() required = false;
	/**
	 * Readonly input reference
	 */
	@Input() readonly = false;
	/**
	 * Tooltip input reference
	 */
	@Input() tooltip = false;
	/**
	 * Tooltip type input reference
	 */
	@Input() tooltipType!: string;
	/**
	 * Uppercase input reference
	 */
	@Input() upperCase = true;
	/**
	 * Variant input reference
	 */
	@Input() variant: any;
	/**
	 * Show errors input reference
	 */
	@Input() showErrors = false;
	/**
	 * Placeholder input reference
	 */
	@Input() placeholder = '';
	/**
	 * Max files input reference
	 */
	@Input() maxFiles: number | null = null;
	/**
	 * File API input reference
	 */
	@Input() useFileApi = true;
	/**
	 * Error in modal input reference
	 */
	@Input() showErrorInModal = false;
	/**
	 * Show description reference
	 */
	@Input() showDescription = false;
	/**
	 * Error in backend input reference
	 */
	@Input() isBackendError = false;
	/**
	 * Control field value input reference
	 */
	@Input() controlFieldValue!: string;
	/**
	 * File error input reference
	 */
	@Input() fileError = false;
	/**
	 * Error menssage input reference
	 */
	@Input() errorMessage!: string;
	/**
	 * Selected index input reference
	 */
	@Input() selectedIndex = 0;
	/**
	 * Selector type input reference
	 */
	@Input() selectorType = 'radio';
	/**
	 * Emits the event when an element loses focus
	 */
	@Output() onblur = new EventEmitter<any>();
	/**
	 * Emits the event when an element gets focus
	 */
	@Output() onfocus = new EventEmitter<any>();
	/**
	 * Emits the event when the value changes
	 */
	@Output() valueChanges = new EventEmitter<any>();
	/**
	 * Emits the event when the field control changes
	 */
	@Output() controlFieldChanges = new EventEmitter<any>();
	/**
	 * Emits the event when a qr code is uploaded
	 */
	@Output() qrCode = new EventEmitter<any>();
	/**
	 * Emits the event when the photo is captured
	 */
	@Output() photoCapture = new EventEmitter<any>();
	/**
	 * Emits the event when the camera is used
	 */
	@Output() cameraUsed = new EventEmitter<any>();

	/**
	 * Tooltips reference
	 */
	@ViewChildren(ImvUiTooltip) tooltips!: QueryList<ImvUiTooltip>;

	/**
	 * Field child reference
	 */
	@ViewChild('field') fieldRef: any;

	/**
	 * Form error component
	 */
	@ContentChildren(FormErrorComponent) errors!: QueryList<FormErrorComponent>;

	/**
	 * RxJS operator to destroy all subscriptions after use
	 */
	private destroy$ = new Subject<void>();

	/**
	 * Triggered when window is scrolled and change tooltip view port
	 */
	@HostListener('window:scroll', ['$event'])
	onScroll() {
		this.tooltips.forEach(tooltip => tooltip.onViewportChanges());
	}

	/**
	 * Triggered when a value changes
	 *
	 * @param value
	 */
	@HostListener('changeValue', [EVENT_DETAIL])
	change(value: any) {
		if (value) {
			this.valueChanged = true;
		} else {
			this.showErrorSlot = false;
		}
		if (this.automaticUploadType.includes(this.type) && value && this.useFileApi) {
			if (this.type === 'file') {
				this.createDocuments(value);
			} else {
				this.createDocument(value);
			}
		} else if (this.type === 'fechacaducidad' && !value) {
			value = '';
		}

		if (this.type === VIDEO_IDENTIFICATION) {
			// Hotfix: The document at least has to be size 1
            value.size = value?.size || 1;
			this.updateValue({ ...value, fileBase64: value.file });
		} else {
			this.updateValue(value);
		}

		if (this.type === 'radio') {
			this.setDescription();
		}
	}

	/**
	 * Called when an input element gets focus
	 */
	@HostListener('focusInput')
	onFocusInput() {
		this.onTouched();
		this.hasFocus = true;
		this.setErrorMessage();
	}

	/**
	 * Called when an input element loses focus
	 */
	@HostListener('blurInput')
	onBlurInput() {
		this.hasFocus = false;
		this.setErrorMessage();
	}

	/**
	 * Handles remove events
	 *
	 * @param file file
	 */
	@HostListener('removeFile', [EVENT_DETAIL])
	removeEventHandler(file: UiUpload | FileUpload) {
		this.onRemoveFile(file);
	}

	/**
	 * Handles download events
	 *
	 * @param file file
	 */
	@HostListener('downloadFile', [EVENT_DETAIL])
	downloadEventHandler(file: any) {
		this.onDownloadFile(file);
	}

	/**
	 * File uploaded reference
	 */
	fileUploaded = false;

	/**
	 * FormControl reference
	 */
	formControl!: FormControl;

	/**
	 * Show error slot reference
	 */
	showErrorSlot = false;

	/**
	 * Error list
	 */
	errorsList: { [Key: string]: string } = {
		required: 'Campo obligatorio',
		requiredArray: 'Subida de documentos obligatoria',
		dni: 'Formato de DNI inválido',
		nie: 'Formato de NIE inválido',
		email: 'Formato de email inválido',
		supportNumber: 'Número de soporte inválido',
		dateValid: 'Fecha inválida',
		dateFormat: 'Formato de fecha inválido',
		dateActual: 'La fecha debe ser mayor a la actual',
		dateTodayOrAfter: 'Error, la fecha no puede ser anterior al día de hoy',
		dateTodayOrBefore: 'Error, la fecha no puede ser posterior al día de hoy',
		noDateActual: 'La fecha no debe ser mayor a la actual',
		noDateLimit150: 'La fecha no debe ser anterior a 150 años',
		noDateLimit50: 'La fecha no debe ser anterior a 50 años',
		noDateLimit16: 'La fecha debe ser anterior a 16 años',
		dateJanuaryPreviousYear: 'La fecha debe ser superior al 1 de enero del año anterior',
		endResidentNoBefore: 'La fecha de fin del permiso de residencia no puede ser anterior a la fecha de inicio',
		endResidentNoBeforeBirthDay: 'La fecha de inicio del permiso de residencia no puede ser posterior al dia de hoy ni anterior a hace 50 años o la fecha de nacimiento',
		residencePermitRenewal: 'La fecha de solicitud de renovación del permiso de residencia no puede ser anterior a la fecha del fin de permiso de residencia',
		numeric: 'Debe contener sólo números',
		numericMoney: 'Sólo números y máximo dos decimales separados por una coma (,)',
		phone: 'Formato de teléfono inválido',
		iban: 'Formato de IBAN inválido',
		notMatch: 'Opción no encontrada',
		invalidToken: 'Código introducido incorrecto',
		minMax: '', // Depends on the min and max values
		max25n: 'Introduce un número entre 1 y 25',
		max12n: 'Introduce un número entre 1 y 12',
		max5n: 'Introduce un número entre 1 y 5',
		notInRangeN: 'Número máximo excedidos o mínimo no superado',
		notInRangeC: 'Caracteres máximo excedidos o mínimo no superado',
		alphabetical: 'Solo debe contener letras y guiones. No dejar espacios antes o después',
		pattern: 'Formato incorrecto',
		notAllowingSpaces: 'No se permiten espacios',
		dniFileName: 'El nombre del documento es demasiado largo. El nombre puede contener como máximo 50 caracteres',
		sameIdError: 'El DNI/NIE del integrante no puede ser igual al DNI/NIE del solicitante',
		sameMemberIdError: 'DNI/NIE de cada integrante no debe repetirse',
		emptyFileError: 'El documento aportado no es un documento válido',
	};

	/**
	 * Tooltip text list
	 */
	tooltipList: { [Key: string]: string } = {
		dni:
			// eslint-disable-next-line max-len
			'En el DNI, el nº de soporte tiene 9 dígitos y se encuentra en la parte inferior de la cara frontal junto a la fecha de validez. <br><br> En el NIE/TIE, el nº de soporte se encuentra en la esquina superior derecha de la cara frontal, y está formado por la letra E más 8 dígitos. <br><br>En la Tarjeta de Ciudadanía Europea (tarjeta verde), sólo figura un número. Añade la letra “C” delante de ese número, y si éste tiene menos de 8 dígitos complétalo con ceros a la izquierda. Ejemplo: Si tiene el número: 1234567, debes indicar C01234567.',
		nie:
			// eslint-disable-next-line max-len
			'En el DNI, el nº de soporte tiene 9 dígitos y se encuentra en la parte inferior de la cara frontal junto a la fecha de validez. <br><br> En el NIE/TIE, el nº de soporte se encuentra en la esquina superior derecha de la cara frontal, y está formado por la letra E más 8 dígitos. <br><br>En la Tarjeta de Ciudadanía Europea (tarjeta verde), sólo figura un número. Añade la letra “C” delante de ese número, y si éste tiene menos de 8 dígitos complétalo con ceros a la izquierda. Ejemplo: Si tiene el número: 1234567, debes indicar C01234567.',
	};

	/**
	 * Tooltip message reference
	 */
	tooltipMessage!: string;

	/**
	 * Show tooltip image reference
	 */
	showTooltipImage = false;

	/**
	 * Focus reference
	 */
	hasFocus = false;

	/**
	 * Values has changed reference
	 */
	valueChanged = false;

	/**
	 * Upload error reference
	 */
	uploadError = false;

	/**
	 * Upload error code reference
	 */
	uploadErrorCode = '';

	/**
	 * Field control disable reference
	 */
	fieldControlDisabled = false;

	/**
	 * Description reference
	 */
	description: string | null = null;

	/**
	 * Full description reference
	 */
	fullDescription!: string;

	requestId!: string;
	/**
	 * Returns if field is of type autocomplete
	 */
	get isAutoComplete() {
		return !this.isDisabled && this.options.length > 10;
	}

	/**
	 * Contains all data
	 *
	 * @param injector
	 * @param _questionnaireService
	 * @param _modalService
	 * @param route
	 * @param _sanitizer
	 */
	constructor(
		private injector: Injector,
		private _questionnaireService: CommonQuestionnaireService,
		private _modalService: CommonModalsService,
		private _store: Store,
		private _sanitizer: DomSanitizer,
	) {
		super(injector);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	/**
	 * Triggered when component is initialize, initialize form control
	 */
	ngOnInit(): void {
		this._store
			.select(<any>SELECT_REQUEST_ID)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: requestId => (this.requestId = <string>requestId),
			});
		this.options = this.options || [];
		const ngControl = this.injector.get(NgControl);
		if (ngControl instanceof FormControlName) {
			this.formControl = this.injector.get(FormGroupDirective).getControl(ngControl);
		} else {
			this.formControl = (ngControl as FormControlDirective).form;
		}
		if (this.formControl) {
			this.setErrorMessage();
			this.setTooltipText();
		}
		this.isDisabled = this.controlFieldValue === '1' ? true : this.isDisabled;
	}

	/**
	 * Triggered on changes, sets error message, tooltip text and update file error value
	 *
	 * @param _changes
	 */
	ngOnChanges(_changes: any) {
		if (this.formControl) {
			this.setErrorMessage();
			this.setTooltipText();
		}

		if (this.fileError && this.type === 'file') {
			this.updateValue([]);
		}
	}

	/**
	 * Iniitialize as the same time than views, gets message errors
	 */
	ngAfterContentInit() {
		this.getMessageErrors();
	}

	/**
	 * Triggered after content and views, subscribes to form control changes and sets error message
	 */
	ngAfterViewInit() {
		if (this.type === 'radio') {
			this.setDescription();
		}
		if (this.formControl) {
			this.formControl.statusChanges.pipe(takeUntil(this.destroy$)).subscribe({
				next: status => {
					this.showErrorSlot = false;
					if (status === 'INVALID') {
						this.valueChanged = true;
						this.setErrorMessage();
					}
				},
			});
		}
	}

	/**
	 * Writes the values on control depending on type
	 *
	 * @param value
	 */
	writeValue(value: any): void {
		if (this.type === 'file' && value) {
			this.value = [];
			this.createDocuments(value);
		} else {
			if (this.type === VIDEO_IDENTIFICATION) {
				this.value = { ...value, id: this.value?.id };
			} else {
				this.value = value;
			}
			if (this.type === 'radio') {
				this.setOptionValue(value);
			} else if (this.type === 'check') {
				this.checked = value;
			}
		}
	}

	/**
	 * Registers the function that must be called on change
	 *
	 * @param fn
	 */
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	/**
	 * Registers the function that must be called on touched
	 *
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	/**
	 * Sets is disable state
	 *
	 * @param isDisabled
	 */
	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
		this.fieldControlDisabled = this.isDisabled;
	}

	/**
	 * Sets the description
	 */
	setDescription() {
		/**
		 * Selected option
		 */
		const selectedOption = this.options.filter(option => option.value === this.value);

		if (selectedOption.length > 0) {
			this.description = selectedOption[0].description ? selectedOption[0].description : null;
			this.fullDescription = selectedOption[0].fullDescription ? selectedOption[0].fullDescription : null;
		}
	}

	/**
	 * Creates document if the control is of type video-identification
	 *
	 * @param value
	 */
	public registerDocument(value: Photo) {
		if (this.type === VIDEO_IDENTIFICATION) {
			this.createDocument(value);
		}
	}

	/**
	 * If sets the selected option for a radio type inputs
	 *
	 * @param value
	 */
	private setOptionValue(value: any) {
		this.options = this.options.map(option => {
			option.checked = String(option.value) === String(value);
			return option;
		});
	}

	/**
	 * Gets message list from error list
	 */
	getMessageErrors() {
		for (const error of this.errors) {
			this.errorsList[error.code] = error.message;
		}
	}

	/**
	 * Sets the tooltip text if exists
	 */
	setTooltipText() {
		if (this.tooltip && this.tooltipList.hasOwnProperty(this.tooltipType)) {
			this.tooltipMessage = this.tooltipList[this.tooltipType];
			this.showTooltipImage = true;
		} else {
			this.tooltipMessage = this.tooltipType;
			this.showTooltipImage = false;
		}
	}

	/**
	 * Sets focus un field control reference
	 */
	setFocus() {
		this.fieldRef.setFocus();
	}

	/**
	 * Removes file
	 *
	 * @param file
	 */
	onRemoveFile(file: UiUpload | FileUpload) {
		this.showErrorSlot = false;
		this.deleteDocument(file);
	}

	/**
	 * @ignore
	 * @param file
	 */
	onDownloadFile(file: any) {}

	/**
	 * Updates form control value
	 *
	 * @param value
	 * @returns
	 */
	private updateValue(value: any[] | null) {

		if (this.isDisabled && !this.automaticUploadType.includes(this.type)) {
			return;
		}

		if (this.type === 'file' && Array.isArray(value)) {
			this.value = value.filter((file) => file.size);
			this.onChange(this.value);
			this.valueChanges.emit(this.value);
			if(this.value.length !== value.length) {
				this.errorMessage = this.errorsList['emptyFileError'];
				this.showErrorSlot = true;
				return;
			};
		}

		if (this.type === 'radio') {
			this.setOptionValue(value);
		}
		/*
		 * Do not remove next line, it's neccessary for ui to know the id coming from DDBB.
		 * This way, when the document it's removed, this id will be sent here and the document
		 * will be removed from DDBB in this.deleteDocument
		 */
		this.value = value;

		this.onChange(value);

		if (this.type === 'check') {
			this.valueChanges.emit(value ? '1' : '2');
		} else {
			this.valueChanges.emit(this.value);
		}

		this.setErrorMessage();
	}

	/**
	 * Sets error message checking all validations
	 */
	private setErrorMessage() {
		if (this.uploadError || this.fileError) {
			this.showErrorSlot = true;
		} else if ((!this.formControl?.valid && this.valueChanged) || (!this.formControl?.valid && this.showErrors) || (!this.formControl?.valid && this.showErrorInModal)) {
			// Priority to error messages from backend
			if (this.formControl.hasError('backendError')) {
				this.errorMessage = this.formControl.errors?.['backendError']?.errorMessage;
				this.showErrorSlot = true;
			} else if (this.formControl.hasError('backendValidationError')) {
				this.errorMessage = this.formControl.errors?.['backendValidationError'].errorMessage;
				this.showErrorSlot = !this.hasFocus;
			} else {
				this.getErrorMessageFromErrorList();
			}
		}
	}

	private getErrorMessageFromErrorList() {
		this.errorMessage = '';
		this.showErrorSlot = false;
		for (const errorCode in this.errorsList) {
			if (this.errorsList.hasOwnProperty(errorCode) && this.formControl.hasError(errorCode)) {
				if (
					((errorCode === 'required' || errorCode === 'requiredArray') && !this.showErrors) ||
					(!this.valueChanged && errorCode !== 'required' && errorCode !== 'requiredArray')
				) {
					this.showErrorSlot = false;
					break;
				}
				this.getMinMaxErrorMessage(errorCode);
				this.showErrorSlot = true;
				break;
			}
		}
	}

	private getMinMaxErrorMessage(errorCode: string) {
		if (this.formControl.hasError('minMax')) {
			this.errorMessage = this.formControl.errors?.['minMax'].errorMessage;
		} else {
			this.errorMessage = errorCode === 'required' && (this.type === 'date' || this.type === 'fechacaducidad') ? 'Fecha no válida' : this.errorsList[errorCode];
		}
	}

	/**
	 * It calls the upload document method for any document in the param if the upload its ok updates the value control
	 *
	 * @param documents
	 */
	private createDocuments(documents: any[]) {
		const documents$ = documents.map(doc => this.uploadDocument(doc));
		const upload$ = forkJoin(documents$);

		this.uploadError = false;
		upload$.pipe(takeUntil(this.destroy$)).subscribe({
			next: files => {
				const updatedFiles = files.filter(file => file.error === false);
				this.uploadError = files.length > updatedFiles.length;
				this.updateValue(updatedFiles);
			},
		});
	}

	/**
	 * It calls the upload document method for document in the param if the upload its ok updates the value control
	 *
	 * @param document
	 */
	private createDocument(document: any) {
		this.uploadError = false;

		if (this.type === VIDEO_IDENTIFICATION && this.value && this.value.id) {
			// Delete previous foto from BBDD
			this._questionnaireService
				.deleteDocument(this.value.id)
				.pipe(takeUntil(this.destroy$))
				.subscribe({ next: res => res });
		}

		this.uploadDocument(document)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: file => {
					this.uploadError = file.error;
					if (this.uploadError) {
						this.updateValue(null);
					} else {
						this.updateValue(file);
					}
				},
			});
	}

	/**
	 * Checks if the document is upload if is not upload it to the backend
	 *
	 * @param document
	 * @returns
	 */
	private uploadDocument(document: any): Observable<any> {
		this.fileUploaded = false;

		if (!document.file) {
			this.errorMessage = this.errorsList['emptyFileError'];
			this.fileError = true;
			return of({ name: null, value: null, fileBase64: null, file: null, id: null, error: true, errorCode: this.errorMessage });
		}

		if (document.id) {
			return of(document);
		}

		const documentDto: DocumentDto = {
			nombre: document.name,
			contenido: document.file,
		};

		const service = this._questionnaireService.createRequestDocument(this.requestId, documentDto);
		return service.pipe(
			map(doc => {
				this.fileUploaded = true;
				this.fileError = false;
				return { ...document, fileBase64: document.file, id: doc.id, file: doc.id, error: false };
			}),
			catchError(err => {
				if (err.error.errors) {
					const error = err.error.errors[0].split(': ')[1];
					this.errorMessage = error;
					this.fileError = true;
				} else {
					this.errorMessage = 'Ha ocurrido un error al subir los documentos';
				}
				return of({ ...document, fileBase64: document.file, file: null, id: null, error: true, errorCode: err.error.errorCode });
			}),
		);
	}

	/**
	 * Deletes documents of form control
	 *
	 * @param document
	 */
	private deleteDocument(document: UiUpload | FileUpload) {
		if (Array.isArray(this.value)) {
			// For files
			const index = this.value.indexOf(document);
			this.value.splice(index, 1);
		} else {
			// For image
			this.value = null;
		}
		this.updateValue(this.value);
		if (document.id) {
			this._questionnaireService
				.deleteDocument(document.id)
				.pipe(takeUntil(this.destroy$))
				.subscribe({ next: res => res });
		}
	}

	/**
	 * Checks optional disabled form controls
	 *
	 * @param event
	 */
	checkOptionals(event: any) {
		if (!this.fieldControlDisabled) {
			this.isDisabled = !this.isDisabled;

			if (this.isDisabled) {
				this.controlFieldValue = '1';
			} else {
				this.controlFieldValue = '2';
			}

			this.value = '';
			this.controlFieldChanges.emit(this.controlFieldValue);
		}
	}

	/**
	 * Obtains the photo done in another device and sets it into the video-identification component
	 */
	handleOpenOtherDevice(): void {
		this._questionnaireService
			.getQrVideoidentification(300, 300)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: res => {
					const sanitized = this._sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${res.qr}`);
					this.qrCode.emit({ sanitized, id: res.id });
				},
				error: err => {
					this._modalService.generalErrorModalEvent('Actualmente el servicio no está disponible, inténtelo de nuevo más tarde.');
				},
			});
	}

	/**
	 * Emits an event if the camera is used
	 *
	 * @param used
	 */
	handleIsUsed(used: any): void {
		this.cameraUsed.emit(used);
	}
}
