import { createReducer, on, Action } from '@ngrx/store';

import { SET_LANG, SET_TEHEME } from './core.actions';
import { ICoreStore } from './core.state';

/**
 * Core store initial state
 */
export const INITIAL_STATE: ICoreStore = {};

/**
 * Creates the reducer
 */
const _coreReducer = createReducer(
	INITIAL_STATE,
	on(SET_LANG, (state, { payload }) => ({
		...state,
		i18n: {
			...state?.i18n,
			currentLang: payload,
		},
	})),
	on(SET_TEHEME, (state, { payload }) => ({
		...state,
		theme: payload,
	})),
);

/**
 * Core reducer
 *
 * @param state Core store
 * @param action action
 * @returns core reducer
 */
export const CORE_REDUCER = (state: ICoreStore | undefined, action: Action): any => _coreReducer(state, action as any);
