import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IGuardService } from './guard.model';
import { PartialUrl } from '../../enums/partial-url.enum';
/**
 * Guard service
 */
@Injectable({ providedIn: 'root' })
export class GuardService {
	/**
	 * Initial guard service state
	 */
	private _initialState: IGuardService = {
		accessToQuestionnaireHome: false,
		hasVisitedForm: false,
		hasVisitedVideoidentification: false,
		usingBrowserButtons: true,
	};

	/**
	 * Guard service state
	 */
	private _state: IGuardService = { ...this._initialState };

	/**
	 * Registry date claim
	 */
	token: any;

	/**
	 * Registry date claim
	 */
	registryDateCaiss: any;

	/**
	 * Final status expedient list
	 */
	expedientList: any;

	/**
	 * True if the user has visited the summary page
	 */
	hasVisitedSummary = false;

	/**
	 * Guard service constructor
	 *
	 * @param _router router
	 */
	constructor(private _router: Router) { }

	/**
	 * Checks for user privileges to access to the questionnaire home
	 *
	 * @returns true if allowed access to questionnaire home
	 */
	canAccessQuestionnaireHome(): boolean {
		return this._state.accessToQuestionnaireHome;
	}

	/**
	 * Checks for user privileges to access to the videoidentification page
	 *
	 * @returns true if allowed access to the videoidentification page
	 */
	canAccessVideoidentification(): boolean {
		return !this._state.hasVisitedForm;
	}

	/**
	 * Checks for user privileges to access to the identification page
	 *
	 * @returns true if allowed access to the identification page
	 */
	canAccessIdentification(): boolean {
		if (sessionStorage.getItem('externalLoginBack') === '1') {
			sessionStorage.removeItem('externalLoginBack');
			this._router.navigateByUrl(PartialUrl.LOGGINGISS_ERROR_004);
			return true;
		} else {
			return !this._state.hasVisitedVideoidentification;
		}
	}

	/**
	 * Checks for user privileges to navigate through questionnaire
	 *
	 * @returns true if allowed access to navigate through questionnaire
	 */
	canNavigateThroughQuestionnare(): boolean {
		return !this._state.usingBrowserButtons;
	}

	/**
	 * Set user privileges to access to questionnaire home
	 */
	allowAccessQuestionnaireHome(): void {
		this._state.accessToQuestionnaireHome = true;
		this.allowNavigationThroughQuestionnaire();
	}

	/**
	 * Denies acces to the identification page
	 */
	denyAccessToIdentification(): void {
		this._state.hasVisitedVideoidentification = true;
	}

	/**
	 * Denies acces to the videoidentification page
	 */
	denyAccessToVideoidentification(): void {
		this._state.hasVisitedForm = true;
	}

	/**
	 * Allows navigation through the questionnaire
	 */
	allowNavigationThroughQuestionnaire(): void {
		this._state.usingBrowserButtons = false;
	}

	/**
	 * Denies navigation through the questionnaire
	 */
	denyNavigationThroughQuestionnaire(): void {
		this._state.usingBrowserButtons = true;
	}

	/**
	 * Controls the questionnaire navigation
	 */
	controlQuestionnaireNavigation(): void {
		if (!this.canNavigateThroughQuestionnare()) {
			this._router.navigateByUrl(PartialUrl.BASE);
		}
		this.denyNavigationThroughQuestionnaire();
	}

	/**
	 * Resets service to initial state
	 */
	resetService() {
		this._state = { ...this._initialState };
		this.registryDateCaiss = '';
		this.hasVisitedSummary = false;
		this.expedientList = '';
	}
}
