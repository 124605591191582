<imv-ui-modal *ngIf="i18n" #modal [isMobile]="isMobile" type="fail" class="modal-align-butons modal-align-title">
	<imv-ui-button eid="dialog-close" theme="secondary" variant=" mdc-button--secondary mdc-button--modal"
		(click)="close()">
		<span slot="text">{{ action }}</span>
	</imv-ui-button>
	<div slot="title">
		<h1 id="dialog-title">{{ title }}</h1>
	</div>
	<p slot="text" id="dialog-description">{{ description }}</p>
	<ul slot="text">
		<ng-container *ngFor="let trace of traces">
			<li slot="text" class="dialog-trace" *ngif="trace?.mensaje">{{ trace.mensaje }}</li>
		</ng-container>
	</ul>
	<div slot="actions" class="mt-0">
		<imv-ui-button eid="dialog-accept" theme="primary"
			[variant]="isMobile ? 'mdc-button--no-margin' : 'mdc-button--secondary mdc-button--modal'"
			class="mobile-full-width mt-0" [fullWidth]="isMobile" (click)="close()">
			<span slot="text">{{ action }}</span>
		</imv-ui-button>
	</div>
</imv-ui-modal>
