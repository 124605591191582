import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@app/core';
import { UiComponentsModule } from './ui-components/public-api';
import { FEATURE_STORE_NAME } from './store/shared.state';
import { sharedReducer } from './store/shared.reducer';
import { SharedEffects } from './store/shared.effects';
import { Error404Module } from './components/error/error404/error404.module';
import { Error500Module } from './components/error/error500/error500.module';
import { MaintenanceModule } from './components/error/maintenance/maintenance.module';
import { DialogsModule } from './dialogs/dialogs.module';
import { PageModule } from './components/page/page.module';

const SHARED_MODULES = [Error404Module, Error500Module, MaintenanceModule, DialogsModule, ReactiveFormsModule, UiComponentsModule, PageModule];
@NgModule({
	declarations: [],
	imports: [...SHARED_MODULES, StoreModule.forFeature(FEATURE_STORE_NAME, sharedReducer), EffectsModule.forFeature([SharedEffects]), I18nModule.forRoot('app')],
	exports: [...SHARED_MODULES],
})
export class SharedModule {}
