import { createSelector } from '@ngrx/store';
import { AppStore } from '@shared/models';

import { FEATURE_STORE_NAME, ICoreStore } from './core.state';

/**
 * Core selectors
 */
export interface ICoreAppStore extends AppStore {
	/**
	 * Feature store name
	 */
	[FEATURE_STORE_NAME]: ICoreStore;
}

/**
 * Core store select
 *
 * @param state Core app store
 * @returns core state
 */
export const SELECT_CORE_STORE = (state: ICoreAppStore) => state[FEATURE_STORE_NAME];

/**
 * Language select
 */
export const SELECT_LANG = createSelector(SELECT_CORE_STORE, (state: ICoreStore) => state?.i18n?.currentLang);

/**
 * Theme select
 */
export const SELECT_THEME = createSelector(SELECT_CORE_STORE, (state: ICoreStore) => state?.theme);
