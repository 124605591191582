import { createSelector } from '@ngrx/store';
import { AppStore } from '@shared/models';

import { IIdentificationStore, FEATURE_STORE_NAME } from './identification.state';

/**
 * Identification selectors
 */
export interface IFeatureAppStore extends AppStore {
	/**
	 * Feature store name
	 */
	[FEATURE_STORE_NAME]: IIdentificationStore;
}

/**
 * Identification store selector
 *
 * @param state feature app store
 * @returns state
 */
export const SELECT_IDENTIFICATION_STORE = (state: IFeatureAppStore) => state[FEATURE_STORE_NAME];

/**
 * User selector
 */
export const SELECT_USER = createSelector(SELECT_IDENTIFICATION_STORE, (state: IIdentificationStore) => state?.user);

/**
 * Select first name selector
 */
export const SELECT_FIRST_NAME = createSelector(SELECT_IDENTIFICATION_STORE, (state: IIdentificationStore) => state?.user?.firstName);
