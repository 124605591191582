import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControlComponent } from './form-control.component';
import { FormErrorComponent } from './form-error.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UiComponentsModule } from '../../ui-components/public-api';
import { CommonQuestionnaireModule } from '../../services/common-questionnaire/public-api';

@NgModule({
	declarations: [FormControlComponent, FormErrorComponent],
	exports: [FormControlComponent, FormErrorComponent],
	imports: [CommonModule, UiComponentsModule, CommonQuestionnaireModule, ReactiveFormsModule],
})
export class FormControlModule { }
