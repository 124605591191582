import { NgModule } from '@angular/core';
import { Error500Component } from './error500.component';
import { CommonModule } from '@angular/common';
import { I18nModule } from '@app/core';
import { UiComponentsModule } from '@shared/ui-components';

const COMPONENTS = [
	Error500Component,

];
@NgModule({
	declarations: [
		...COMPONENTS
	],
	imports: [
		I18nModule.forRoot('app'),
		CommonModule,
		UiComponentsModule,
	],
	exports: [...COMPONENTS
	],
})
export class Error500Module { }